<template>
    <div style="height: 95%;background: #fff;padding: 20px;border-radius: 15px;">
        <img src="../../assets/images/back.png" alt="" style="float:right;width:40px;cursor:pointer" @click="backHotel">
        <div style="font-size:20px;margin-bottom:20px;">{{hotelName}}</div>
        <el-menu :default-active="defaultActive" class="el-menu-demo" mode="horizontal" router @select="handleSelect">
            <el-menu-item index="/manage">房间管理</el-menu-item>
            <el-menu-item index="/mould">房型管理</el-menu-item>
        </el-menu>
        <!--<roomTab/>-->
        <div style="margin-top:20px;height: 83%;overflow:scroll">
            <router-view :hotelId="hotelId"/>
        </div>
    </div>
</template>

<script>
    // import roomTab from './roomTab.vue'
    // import manage from './manage.vue'
    // import mould from './mould.vue'
    export default {
        // components: {
        //     manage,mould // eslint-disable-line no-unused-vars
        // },
        name: "Room",
        data(){
            return{
                defaultActive:'',
                hotelId:this.$cookies.get("hotelId"),
                hotelName:this.$cookies.get("hotelName"),
                activePath:sessionStorage.getItem('activePath')
            }
        },
        mounted() {
            this.defaultActive = sessionStorage.getItem('activePath') || '/';
        },
        // watch: {
        //     activeIndex(newValue){
        //         if (newValur != this.$route.path) {
        //             this.defaultActive = this.$route.path;
        //         }
        //     }
        // },
        methods:{
            //返回酒店列表（HotelMange页面）
            backHotel(){
                this.$router.push({
                    path:'./HotelMange',
                })
            },
            handleSelect(activePath) {
                this.activePath=activePath
                console.log('activepaht',this.activePath)
                sessionStorage.setItem('activePath', activePath);
            },
            // getParams:function(){
            //     var name=this.$route.query.hotelName
            //     var id=this.$route.query.hotelId
            //     this.hotelId=id
            //     this.hotelName=name
            //     console.log('name',this.hotelId,this.hotelName)
            // }
        },
        created:function(){
            // this.getParams()

            this.$router.push({
                path:this.activePath,
            })
        },
        // watch:function(){
        //     console.log('defaultActive',this.defaultActive)
        //     // '$route':'getParams'
        // }
    }
</script>

<style scoped>

</style>
